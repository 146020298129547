import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import CategoryHeading from '@fuww/library/src/CategoryHeading';
import SpacingContainer
from '@fuww/library/src/SpacingContainer';
import breakpoints from '@fuww/library/src/Theme/breakpoints';
import { RelatedItemContainer, RelatedItem }
from '@fuww/library/src/List/RelatedItem';
import CardWithLink from '../../CardWithLink';
import articlesQuery from '../../../lib/queries/news/articlesQuery.graphql';
import {
  relatedCategoryIds as categoryIds,
} from '../../../lib/mainCategoryIds';
import withQuery from '../../../lib/withQuery';
import { useSiteContext } from '../../SiteContext';
import messages from '../../../lib/messages.mjs';
import {
  relatedNewsImageConfigurations,
} from '../../../lib/imageConfigurations';

const RelatedNews = ({
  data, variables,
}) => {
  const articles = data.universalNewsArticles;
  const intl = useIntl();

  return (
    <>
      <CategoryHeading>
        {intl.formatMessage(messages.relatedNews)}
      </CategoryHeading>
      <SpacingContainer />
      <RelatedItemContainer>
        {articles.map((article) => (
          <RelatedItem key={article.id}>
            <CardWithLink
              newsArticle={article}
              variables={variables}
              withPageMargins
              sizes={`
                (min-width: 1100px) 220px,
                (min-width: ${breakpoints.lg}px) 21vw,
                (min-width: ${breakpoints.sm}px) 31vw,
                100vw
              `}
            />
          </RelatedItem>
        ))}
      </RelatedItemContainer>
    </>
  );
};

RelatedNews.propTypes = {
  data: PropTypes.shape({
    universalNewsArticles: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
    })).isRequired,
  }),
  variables: PropTypes.shape({}).isRequired,
};

RelatedNews.defaultProps = {
  data: {},
};

const RelatedNewsWithQuery = withQuery(RelatedNews);

const errorMessage = 'Error while loading news articles...';

const RelatedNewsWithData = ({
  articleId, tagSlugs, showImage, showCategory, noBorderFirstItem,
}) => {
  const { newsboard } = useSiteContext();

  const variables = {
    newsboard,
    withoutDescriptionAndCreator: true,
    withoutSummaryAndLabel: false,
    sort: 'CREATED_AT',
    limit: 3,
    tagSlugs,
    notInIds: articleId,
    categoryIds,
    imageConfigurations: relatedNewsImageConfigurations,
  };

  return (
    <RelatedNewsWithQuery
      query={articlesQuery}
      variables={variables}
      errorMessage={errorMessage}
      showCategory={showCategory}
      showImage={showImage}
      noBorderFirstItem={noBorderFirstItem}
    />
  );
};

RelatedNewsWithData.propTypes = {
  tagSlugs: PropTypes.arrayOf(PropTypes.string),
  articleId: PropTypes.number,
  showImage: PropTypes.bool,
  showCategory: PropTypes.bool,
  noBorderFirstItem: PropTypes.bool,
};

RelatedNewsWithData.defaultProps = {
  articleId: null,
  tagSlugs: null,
  showImage: null,
  showCategory: null,
  noBorderFirstItem: null,
};

export default RelatedNewsWithData;
