import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

const getArticleSubjectType = (
  prefix: string,
  hasLocalNews: boolean,
) => (
  hasLocalNews
    ? upperFirst(`${camelCase(prefix)}NewsArticle`)
    : 'NewsArticle'
);

export default getArticleSubjectType;
